import { LRParser, LocalTokenGroup } from '@lezer/lr';
import { LRLanguage, indentNodeProp, delimitedIndent, foldNodeProp, foldInside, LanguageSupport } from '@codemirror/language';
import { styleTags, tags } from '@lezer/highlight';
import { parser as parser$1 } from '@lezer/xml';
import { parseMixed } from '@lezer/common';

// This file was generated by lezer-generator. You probably shouldn't edit it.
const strict = 1,
  graph = 2,
  digraph = 3,
  subgraph = 4,
  node = 5,
  edge = 6;

const keywordMap = {
  strict,
  graph,
  digraph,
  subgraph,
  node,
  edge
};

function keywords(name) {
  let found = keywordMap[name.toLowerCase()];
  return found == null ? -1 : found;
}

// This file was generated by lezer-generator. You probably shouldn't edit it.
const parser = LRParser.deserialize({
  version: 14,
  states: "+WO]QPOOOOQO'#Cg'#CgOhQPO'#CfO`QPO'#CfOyQPOOO!OQPO'#D`O#]OQO'#ClOOQO'#D`'#D`OOQO,59Q,59QO#hQPO,59QO$hQPO'#CoQOQPOOO$oQPO'#DUO$tQPO,59VO#]OQO'#DVOOOO'#DV'#DVO&ROQO'#DbOOOO'#Cn'#CnO&^OQO,59WOOQO1G.l1G.lO'gQPO'#CrO'nQPO'#DgO(oQPO'#CvOOQO'#Cu'#CuOyQPO'#CuOOQO'#Dg'#DgO)QQPO'#CyO)VQPO'#CqOOQO'#DR'#DROOQO'#Dm'#DmOOQO'#Df'#DfO*TQPO'#DWO*xQPO,59ZOOQO,59Z,59ZO+PQPO'#DSOOQO,59p,59pOOQO-E7S-E7SO+UOQO,59qOOOO-E7T-E7TOOQO1G.r1G.rO+ZQPO'#CsOOQO,59^,59^O+ZQPO'#DXO+iQPO,59`OOQO,59b,59bOOQO,59a,59aO+ZQPO,59eO,jQPO'#CwOOQO'#DY'#DYO,{QPO,59]OOQO'#DO'#DOO-sQPO'#D[O.XQPO,59iOOQO,59r,59rOOQO-E7U-E7UOOQO1G.u1G.uO/VQPO,59nOOOO1G/]1G/]O/}QPO,59_O&cQPO'#CrOOQO,59s,59sOOQO-E7V-E7VOOQO'#C{'#C{OOQO1G/P1G/POOQO'#Cz'#CzO1RQPO'#DjOOQO'#DZ'#DZO1jQPO,59cOOQO,59c,59cOOQO-E7W-E7WOOQO,59v,59vO1{QPO1G/TOOQO-E7Y-E7YO+ZQPO1G.yOOQO,5:U,5:UOOQO-E7X-E7XOOQO1G.}1G.}OOQO7+$e7+$e",
  stateData: "2s~O!ROSVOSWOS~OPROQPORPO~O[VO]VO^TOaUOdYX~OdYO~O!T[Od!SXQ!SXS!SXT!SXU!SX[!SX]!SX^!SXa!SXl!SXs!SXt!SXw!SX![!SX!]!SX!_!SX!`!SXp!SX~O!V^O!X_O!W!UP~O[VO]VO^TOaUOdYa~OQmOSfOTmOUmO[VO]VO^TOaUOdYO~OwqO~P#yO^sO~O!T[Od_aQ_aS_aT_aU_a[_a]_a^_aa_al_as_at_aw_a![_a!]_a!__a!`_ap_a~O!V^O!X_O!W!UX~O!WwO~O![xOQfXSfXTfXUfX[fX]fX^fXafXdfXlfXsfXtfXwfX!]fX!`fX~O!_nX~P&cO!]zOQ!ZXS!ZXT!ZXU!ZX[!ZX]!ZX^!ZXa!ZXd!ZXl!ZXs!ZXt!ZXw!ZX!`!ZX~O[VO]VO^TOaUOdjX~O!_!OO~Ol!POs!SOt!SOQeXSeXTeXUeX[eX]eX^eXaeXdeXweX!`eX~O!`!VOQzXSzXTzXUzX[zX]zX^zXazXdzXwzX~Ow!XO~P#yOl!PO~O!W!ZO~O[VO]VO^TOaUO~O!]zOQhaShaThaUha[ha]ha^haahadhalhashathawha!`ha~O[VO]VO^TOaUOp!fO~Ol!POQeaSeaTeaUea[ea]ea^eaaeadeawea!`ea~OSfO[VO]VO^TOaUOdYO~Ol!POs!SOt!SOQqaSqaTqaUqa[qa]qa^qaaqadqawqa!`qa~Ol!POQvaSvaTvaUva[va]va^vaavadvawva!`va~O![!kOQgaSgaTgaUga[ga]ga^gaagadgalgasgatgawga!]ga!`ga~O!]!lO!`!lO[!^X]!^X^!^Xa!^Xp!^X~O[VO]VO^TOaUOp!nO~Ol!POQqiSqiTqiUqi[qi]qi^qiaqidqiwqi!`qi~O",
  goto: "&q!bPPPPPPPPPP!c!fPPP!l!lP!z!}P#Y#^#f#j#j#o#tP#|$U$[P#Y$_PP#Y#YP$c$i$p$v$|%[%bPPP%hP&WPPP&^&bPP&iPP&mRSOQQORXRiVQXYfpxz!O!P!T!e!kRbUQZSUgYp!TR}hTnYpUeYp!TR!^zTyd!]ViYp!TVhYp!T]!Qkr!R!U!Y!iSlYpT!c!P!eXjYp!P!eR!a!OT!Tk!UQ]TRt]S`U^Rv`QpYR!WpQ{eR!_{Q!RkQ!YrU!g!R!Y!iR!i!UQ!e!PR!m!eQ!UkR!j!UQWQQcXSdYpQ|fQ![xS!]z!TQ!`!OS!b!P!eR!o!kQaURu^ToYpSkYpR!h!TT!d!P!eTrYp",
  nodeNames: "⚠ strict graph digraph subgraph node edge LineComment BlockComment Graph Header Graphtype Name Number String ConcatString HTMLString < HTMLStringContent Body { SimpleStatement Node Port NodeList Subgraph SubgraphHeader Attributes [ Attribute AttributeName AttributeValue ] EdgeStatement Edgeop -- -> GraphAttributeStatement AttributeStatement }",
  maxTerm: 63,
  skippedNodes: [0,7,8],
  repeatNodeCount: 7,
  tokenData: ")k~RkXY!vYZ!v]^!vpq!vrs#Xst$utu%^{|&R|}&W}!O&]!O!P&q!P!Q'a!Q!['P![!](q!]!^(v!^!_({!_!`)Q!c!}%^!}#O)V#P#Q)[#R#S%^#T#o%^#o#p)a#q#r)f#t;'S%^;'S;=`%{<%lO%^~!{S!R~XY!vYZ!v]^!vpq!v~#[VOr#Xrs#qs#O#X#O#P#v#P;'S#X;'S;=`$o<%lO#X~#vO^~~#yRO;'S#X;'S;=`$S;=`O#X~$VWOr#Xrs#qs#O#X#O#P#v#P;'S#X;'S;=`$o;=`<%l#X<%lO#X~$rP;=`<%l#X~$zSV~OY$uZ;'S$u;'S;=`%W<%lO$u~%ZP;=`<%l$u~%cW[~tu%^!Q![%^!c!}%^#R#S%^#T#o%^#t;'S%^;'S;=`%{<%lO%^~&OP;=`<%l%^~&WO!T~~&]O!]~~&`S}!O&l!O!P&q!Q!['P!`!a'[~&qOs~~&tP!Q![&w~&|P]~!Q![&w~'UQ]~!O!P&w!Q!['P~'aOt~~'dQz{'j!P!Q$u~'mTOz'jz{'|{;'S'j;'S;=`(k<%lO'j~(PVOz'jz{'|{!P'j!P!Q(f!Q;'S'j;'S;=`(k<%lO'j~(kOW~~(nP;=`<%l'j~(vO![~~({O!`~~)QOa~~)VO!_~~)[Ol~~)aOp~~)fOd~~)kOw~",
  tokenizers: [1, new LocalTokenGroup("d~RQ!^!_X!`!a^~^O!V~~cO!W~~", 19, 55)],
  topRules: {"Graph":[0,9]},
  specialized: [{term: 12, get: (value, stack) => (keywords(value) << 1), external: keywords}],
  tokenPrec: 0
});

const language = LRLanguage.define({
  parser: parser.configure({
    props: [
      styleTags({
        "strict digraph graph subgraph node edge": tags.keyword,
        "Name": tags.literal,
        "String HTMLString < >": tags.string,
        "Number": tags.number,
        "Node/Name": tags.definition(tags.variableName),
        "Port/Name": tags.variableName,
        "AttrName/Name": tags.definition(tags.propertyName),
        "[ ]": tags.squareBracket,
        "{ }": tags.brace,
        ", ;": tags.separator,
        ":": tags.punctuation,
        "-> -- = +": tags.operator,
        LineComment: tags.lineComment,
        BlockComment: tags.blockComment
      }),
      indentNodeProp.add({
        Body: delimitedIndent({ closing: "}" }),
        Attributes: delimitedIndent({ closing: "]" })
      }),
      foldNodeProp.add({
        "Body Attributes": foldInside,
        BlockComment(tree) { return { from: tree.from + 2, to: tree.to - 2 } }
      })
    ],
    wrap: parseMixed(node => {
      return node.name == "HTMLStringContent" ? { parser: parser$1 } : null
    })
  }),
  languageData: {
    closeBrackets: { brackets: ["[", "{", '"', "<"] },
    commentTokens: { line: "#", block: { open: "/*", close: "*/" } }
  }
});

function dot() {
  return new LanguageSupport(language);
}

export { dot };
